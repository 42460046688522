<ng-multiselect-dropdown [placeholder]="'Select Icon'" [settings]="dropdownIconSettings" [data]="iconList"
    (onSelect)="onIconSelect($event)" [(ngModel)]="this.modelName"
    [ngModelOptions]="{standalone: true}">
    <ng-template #optionsTemplate let-item let-option="option" let-id="id" let-isSelected="isSelected">
        <div style="padding:1px 10px;margin-right:10px;right:2px;top:-2px">
            <img [src]="getItems[id].url" style="width: 18px; margin: 0 20px;" />
            <label style="color: #333;min-width: 130px;">
                {{getItems[id].name}}
            </label>
        </div>
    </ng-template>
    <ng-template #optionSelectedTemplate let-option="option" let-id="id">
        <div>
            <img [src]="getItems[id].url" style="width: 18px; margin-right: 2px;" />
            {{getItems[id].name}}
        </div>
    </ng-template>
</ng-multiselect-dropdown>