<app-topbar>
</app-topbar>
<div class="wrapper">
    <app-sidebar></app-sidebar>
    <div class="bg-light w-100">
        <div class="container-fluid pt-4">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<!-- footer -->
<!-- <app-footer></app-footer> -->