<!-- <footer class="footer-container">
	
</footer> -->
<footer>
<div class="container">
	<div class="row justify-content-center">
		<div class="col-lg-12 col-md-12 col-centered customWidth">
			<div class="start-trial">
				<div class="row">
					<div class="col">
						<h6>Company</h6>
						<ul class="footer-menu">
							<li><a href="https://www.oilandgasclub.com/about-us/" target="_blank">About Us</a></li>
							<li><a href="https://www.oilandgasclub.com/contact-us/" target="_blank">Contact Us</a>
							</li>
							<li><a href="https://www.oilandgasclub.com/career/" target="_blank">Career</a></li>
						</ul>
					</div>
					<div class="col">
						<h6>Work with us</h6>
						<ul class="menu-company">
							<li><a href="https://www.oilandgasclub.com/become-our-trainer/" target="_blank">Become
									an Instructor</a></li>
							<li><a href="https://www.oilandgasclub.com/guest-blogging/" target="_blank">Blog as
									Guest</a></li>
							<li><a href="https://www.oilandgasclub.com/affiliate-program/" target="_blank">Become an
									Affiliate</a></li>
						</ul>
					</div>
					<div class="col">
						<h6>For Business</h6>
						<ul class="menu-company">
							<li><a href="http://www.oilandgasclub.com/corporate-training/" target="_blank">Corporate
									Training</a></li>
							<li><a href="https://www.oilandgasclub.com/courses-offered/" target="_blank">Courses
									Offered</a></li>
							<li><a href="https://www.oilandgasclub.com/why-oilandgasclub/" target="_blank">Why
									Oilandgasclub.com</a></li>
						</ul>
					</div>
					<div class="col">
						<h6>More</h6>
						<ul class="menu-company">
							<li><a href="http://www.oilandgasclub.com/refund-cancellation-policy/"
									target="_blank">Refund and Cancellation Policy</a></li>
							<li><a href="https://www.oilandgasclub.com/terms-and-conditions" target="_blank">Terms
									and Conditions</a></li>
							<li><a href="https://www.oilandgasclub.com/privacy-policy/" target="_blank">Privacy
									Policy</a></li>
						</ul>
					</div>
					<div class="col custom-col">
						<h6>Social Links</h6>
						 <a href="https://www.facebook.com/oilandgasclub/" target="_blank"><img
									src="assets/s3/oilandgasclub_facebook.svg"  alt="" width="50" height="50"></a>
						 
						 <a href="https://www.linkedin.com/company/oilandgasclub-com/" target="_blank"><img
									src="assets/s3/oilandgasclub_linkedin.svg"  alt="" width="50" height="50"></a>
						 
					 <a href="https://x.com/oilandgasclub/" target="_blank"><img src="assets/s3/oilandgasclub_x.svg"  alt=""
									width="50" height="50"></a> 
						<!-- <input type="text" placeholder="Enter your e-mail address"> -->
						<!-- <button type="btn">Submit</button> -->
					</div>
				</div>
				<div class="row">
				<div class="col-md-12 no-padding footer-nav-container">
					<ul>
						
							<a class="footer-nav-item" href="https://www.oilandgasclub.com/partner-us/">Partner Us</a>
							<a class="footer-nav-item" href="https://www.oilandgasclub.com/membership/"
								target="blank">Membership</a>
							<a class="footer-nav-item" href="https://www.oilandgasclub.com/blog/" target="blank">Blog</a>
							<a class="footer-nav-item" href="https://www.oilandgasclub.com/policies/"
								target="blank">Policies</a>
							<a class="footer-nav-item" href="https://www.oilandgasclub.com/in-house-solutions/"
								target="blank">In-house Solution</a>
							<a class="footer-nav-item" href="https://www.oilandgasclub.com/build-your-portfolio/"
								target="blank">Build your Portfolio</a>
							<a class="footer-nav-item" href="https://www.oilandgasclub.com/mission-and-vision/"
								target="blank">Mission and Vision</a>
						
					</ul>
				</div>
				</div>
			</div>
		</div>
	</div>
	
</div>
<div class="copy-right">
<div class="row">
<div class="no-padding new-footer-end">
	<div class="container">
		<p>Copyright © 2024 Acusstreak. All rights reserved. The certification names and logos are the trademarks of their respective owners.</p>
	</div>
	</div>
	</div>
</div>
</footer>